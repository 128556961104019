<template>
  <div class="game-list-container">
    <!-- 搜索区域 -->
    <div class="search-section">
      <div class="search-header">
        <h2 class="section-title">游戏库</h2>
        <p class="section-subtitle">发现精彩游戏，开启你的游戏之旅</p>
      </div>
      <div class="search-box">
        <el-input v-model="input"
                  placeholder="搜索游戏名称"
                  prefix-icon="el-icon-search"
                  clearable
                  class="search-input">
        </el-input>
        <el-select v-model="input1"
                   clearable
                   filterable
                   placeholder="选择游戏类型"
                   class="type-select">
          <el-option v-for="item in gametype"
                     :key="item"
                     :label="item"
                     :value="item">
          </el-option>
        </el-select>
        <el-input v-model="input2"
                  placeholder="游戏评价"
                  class="review-input">
        </el-input>
        <el-button type="primary" @click="pcsearch" class="search-btn">
          <i class="el-icon-search"></i> 搜索
        </el-button>
        <el-button type="success" @click="dialogFormVisible = true" class="add-btn">
          <i class="el-icon-plus"></i> 获取游戏
        </el-button>
      </div>
    </div>

    <!-- 游戏列表 -->
    <div class="game-grid" v-loading="loading" element-loading-text="加载中...">
      <el-card v-for="i in gameList"
               :key="i.id"
               class="game-card"
               @click.native="changeGogame(i)"
               shadow="hover">
        <div class="game-image">
          <img :src='i.imgsrc' class="game-cover"/>
          <div class="game-overlay">
            <div class="overlay-content">
              <el-button type="primary" size="small" class="view-btn">
                <i class="el-icon-view"></i> 查看详情
              </el-button>
              <div class="game-stats">
                <span><i class="el-icon-user"></i> {{ i.recentreviews }}</span>
                <span><i class="el-icon-star-on"></i> {{ i.goodrating }}%</span>
              </div>
            </div>
          </div>
        </div>
        <div class="game-info">
          <h3 class="game-title">{{ i.name }}</h3>
          <div class="game-tags">
            <el-tag type="danger" effect="dark" class="price-tag">
              <i class="el-icon-money"></i> {{ i.price }}
            </el-tag>
            <el-tag type="success" effect="dark" class="rating-tag">
              <i class="el-icon-star-on"></i> {{ i.goodrating }}%
            </el-tag>
            <el-tag type="info" effect="dark" class="review-tag">
              <i class="el-icon-chat-dot-round"></i> {{ i.recentreviews }}
            </el-tag>
          </div>
          <div class="game-description">{{i.description}}</div>
          <div class="game-meta">
            <el-tag size="small" effect="plain">
              <i class="el-icon-date"></i> {{ i.releasedate }}
            </el-tag>
            <el-tag size="small" effect="plain">
              <i class="el-icon-office-building"></i> {{ i.developers }}
            </el-tag>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 分页 -->
    <div class="pagination-section">
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="[12, 24, 36, 48]"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>

    <!-- 获取游戏对话框 -->
    <el-dialog title="获取新游戏"
               :visible.sync="dialogFormVisible"
               width="30%"
               class="game-dialog"
               v-loading="loading"
               element-loading-text="获取中 预计10s-15s 请稍等">
      <el-form>
        <el-form-item label="游戏ID">
          <el-input v-model="newgameid"
                    placeholder="请输入Steam游戏ID"
                    autocomplete="off">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="newgameget">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Swiperlist from "@/components/Swiperlist/index";
import axios from "axios";
import api from "@/axios/api";
import {GAME_API} from "@/api/game-api";

export default {
  data() {
    return {
      // 以下为PC游戏参数
      gameList: [], // PC游戏列表
      currentPage: 1, // PC游戏当前页码，初始值设为1
      pageSize: 20, // PC游戏每页显示的数量
      total: 0, // PC游戏的总数量
      input: '', // PC游戏名称的搜索输入
      input1: '', // PC游戏标签的搜索输入
      input2: '', // PC游戏评价的搜索输入
      loading: false, // 加载状态
      newgameid: 0, // 新游戏ID
      gametype: [], // 游戏类型列表

      dialogFormVisible: false, // 对话框可见性

    };
  },
  methods: {

    // 搜索PC游戏
    pcsearch() {
      this.currentPage = 1;
      this.gameget();
    },

    // 获取PC游戏数据
    async gameget() {
      this.loading = true;
      this.gameList = [];
      try {
        const res = await api.get(GAME_API.GET_PAGEGAME, {
          params: {
            pagenum: this.currentPage,
            pagesize: this.pageSize,
            name: this.input,
            tab: this.input1,
            Recentreviews: this.input2,
          }
        });

        this.loading = false;
        this.gameList = res.pagegame;
        this.total = res.countgame;
        this.gameList.forEach(game => {
          game.imgsrc = `https://media.st.dl.eccdnx.com/steam/apps/${game.gameid}/header.jpg`;
          game.price = game.price === 0 ? "免费开玩" : game.price+"元";
        });
      } catch (error) {
        this.loading = false;
        console.error("获取游戏数据时出错：", error);
      }
    },

    // 获取游戏类型数据
    async getgametype() {
      try {
        const res = await axios.get(this.$globle.allurl + "typecount");
        this.gametype = res.data;
      } catch (error) {
        console.error("获取游戏类型数据时出错：", error);
      }
    },

    // 切换到游戏详情页面
    changeGogame(res) {
      this.$router.push({
        name: "gameDetail",
        query: {
          id: res.id,
          gameid: res.gameid
        },
      });
    },

    // 处理分页大小变化
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.gameget();
    },

    // 处理当前页变化
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.gameget();
    },

    // 获取新游戏数据
    async newgameget() {
      this.loading = true;
      try {
        const res = await axios.get(this.$globle.pyurl + `predict/${this.newgameid}`);
        if (res.data.status === "success") {
          this.$router.push({
            name: "gameDetail",
            query: {
              id: res.data.data,
            },
          });
          this.dialogFormVisible = false;
        } else if (res.data.status === "failed") {
          this.$message({
            type: "error",
            message: "获取失败"
          });
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: "获取数据时出错"
        });
      } finally {
        this.dialogFormVisible = false;
        this.loading = false;
      }
    },


  },
  mounted() {
    // 组件挂载完成后获取初始数据
    this.gameget();
    this.getgametype();
  },
  components: {

    // Swiperlist,
  },
};
</script>

<style scoped>
.game-list-container {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.search-section {
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
  margin-bottom: 30px;
}

.search-header {
  margin-bottom: 20px;
  text-align: center;
}

.section-title {
  font-size: 28px;
  color: #303133;
  margin: 0 0 10px;
  font-weight: 600;
}

.section-subtitle {
  color: #909399;
  font-size: 16px;
  margin: 0;
}

.search-box {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.search-box .el-input {
  width: 300px;
}

.type-select {
  width: 200px;
}

.review-input {
  width: 200px;
}

.search-btn, .add-btn {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.search-btn:hover, .add-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  padding: 20px 0;
}

.game-card {
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
  background: white;
  border: none;
}

.game-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0,0,0,0.12);
}

.game-image {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.game-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.game-card:hover .game-cover {
  transform: scale(1.08);
}

.game-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.7));
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
}

.game-card:hover .game-overlay {
  opacity: 1;
}

.overlay-content {
  text-align: center;
  color: white;
}

.game-stats {
  margin-top: 15px;
  font-size: 14px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.game-stats span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.view-btn {
  transform: translateY(20px);
  transition: all 0.3s ease;
  border-radius: 20px;
  padding: 8px 20px;
}

.game-card:hover .view-btn {
  transform: translateY(0);
}

.game-info {
  padding: 20px;
}

.game-title {
  margin: 0 0 15px;
  font-size: 20px;
  color: #303133;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.game-tags {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.game-description {
  color: #606266;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.game-meta {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding-top: 15px;
  border-top: 1px solid #f0f0f0;
}

.pagination-section {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
}

.game-dialog {
  border-radius: 12px;
}

.dialog-footer {
  text-align: right;
}

/* 响应式布局 */
@media screen and (max-width: 768px) {
  .search-section {
    padding: 20px;
  }
  
  .section-title {
    font-size: 24px;
  }
  
  .search-box {
    flex-direction: column;
  }
  
  .search-box .el-input,
  .type-select,
  .review-input {
    width: 100%;
  }
  
  .search-btn, .add-btn {
    width: 100%;
  }
  
  .game-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  
  .game-image {
    height: 180px;
  }
}
</style>